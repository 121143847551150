.services-container{


    background-image: url('../images/toptopimg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
         top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      
      height: 60vh;
      width: 100vw;
      pointer-events: none;
     z-index: -2;
    

  
      

}
.services-title{
    height: 30vh;
    width: 50vw;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: -1; */
    position: absolute;
}
.services-title >h1{
    text-align: center;
    font-size: 54px;
  
      font-weight: 400;
   
   
    font-family: 'Raleway','Arial',sans-serif;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
}
.services-wrapper{
    padding-top: 50vh;
    height: 100vh;
}