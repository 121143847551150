


.homecombo-container{
    background-image: url('../images/a4.png') ;
    /* background-position: center; */
    background-size: contain;
    background-repeat: no-repeat;
 
    
    /* background-position: fixed; */
    pointer-events: none;

    
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    
    
    /* background-color: rgba(228, 203, 94, 0.877); */
    border-bottom: 1px solid rgb(160, 145, 132);
}


.homecombo-wrapper{

    display: flex;
}
.foot-describe{
height: 50vh;
width: 80vw;
margin: auto;
}


.homecombo-display-flex{
    
    
    display:flex;
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 25vw;
        height: 5vh;
      
    
  
    
    
}
/* .move-to-right{
  margin-right: 10%;
    right: 0;
    position: absolute;
} */
.move-to-left{
    margin-left: 10%;
    left: 0;
    position: absolute;
}
.homecombo-left>h2{
    text-align: start;
    letter-spacing: 2px;
    font-size: 36px;
    font-family: 'Marcellus',Georgia,"Times New Roman",serif;

}
.homecombo-display-flex>h3{
    color: #475052;
    width: 15vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;

    align-self: center;
    text-align: start;
    
    
}
.homecombo-display-flex>h4{
    color: #1b4852;
    width:10vw;
    margin-left: 5%;
    
    font-size: 22px;
    font-family: 'Muli',sans-serif;
 
    align-self: center;
    text-align: start;
    
}
.homecombo-left > p {
    text-align: left;
    line-height: 1.7em;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
    margin-bottom: 10%;

}
.homecombo-left{
  
    margin-left: 10%;
    height: 50vh;
    width: 40vw;
}







.homecombo-right{
    margin-left: 10%;
    height: 50vh;
    width: 30vw;
   

}
/*
#EDECEC
*/
.homecombo-right>img{
        
    height: 50vh;
    width: 30vw;
    
    object-fit: contain;
}



@media (max-width:1600px){
    .homecombo-container{
        height: 100vh;
    }

    
    .homecombo-right{
        margin-left: 0%;
         height: 100vh;
         width: 50vw;
        
     
     }
     /*
     #EDECEC
     */
     .homecombo-right>img{
             
         height: 100vh;
         width: 50vw;
         
         object-fit: cover;
     }
 
     .homecombo-left{
   
        margin-right: 10%;
         height: 100vh;
         width: 50vw;
     }


}


@media (max-width:450px){
    .homecombo-container{
        height: 200vh;
       
      

    }
    .homecombo-wrapper{
        flex-direction: column-reverse;
    }

    .homecombo-right{
       margin-left: 0%;
        height: 50vh;
        width: 100vw;
       
    
    }
    /*
    #EDECEC
    */
    .homecombo-right>img{
            
        height: 50vh;
        width: 100vw;
        
        object-fit: cover;
    }

    .homecombo-left{
  
   
        height: 100vh;
        width: 50vw;
      
    }


    
.homecombo-display-flex{
    
    
    
  
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 80vw;
        height: 10vh;
      
    
  
    
    
}

.homecombo-display-flex>h3{
width: 100vw;
    
}
.homecombo-display-flex>h4{

    width:25vw;
 
    
}
    
}





