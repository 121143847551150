


.homebody-container{
    background-image: url('../images/a4.png') ;
    height: 100vh;
    width: 100vw;
    background-position:top ;
    background-size: contain;
 background-repeat: no-repeat;
 background-color: #EDECEC;
 overflow: hidden;
  
    /* overflow: hidden; */
    border-bottom: 1px solid rgb(160, 145, 132);
    pointer-events: none;
}

.homebody-wrapper{

    display: flex;
}

.homebody-right{
    /* padding-right: 10%; */
    margin-left: 10%;
    height: 50vh;
    width: 40vw;
  

    
    

}

.homebody-display-flex{
    
    display:flex;
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 25vw;
        height: 5vh;
      
    
  
    
    
}
.homebody-right>h2{
    text-align: end;
    letter-spacing: 2px;
    font-size: 36px;
    font-family: 'Marcellus',Georgia,"Times New Roman",serif;

}
.homebody-display-flex>h3{
    color: #475052;
    width: 15vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
text-align: center;
    align-self: center;
    
}
.homebody-display-flex>h4{
    color: #1b4852;
    text-align: end;
    padding-left: 5%;
    width: 10vw;
    
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  
    align-self: center;
    
}
.homebody-right > p {
    text-align: left;
    line-height: 1.7em;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
  

}
.homebody-left{
    padding-right: 10%;
    height: 50vh;
    width: 30vw;
   

}
/*
#EDECEC
*/
.homebody-left>img{
        
    height: 50vh;
    width: 30vw;
    
    object-fit: contain;
}




/*home facial */

@media (max-width:1600px){
    .homebody-container{
        height: 200vh;
    }

    
    .homebody-left{
        margin-left: 0%;
         height: 100vh;
         width: 50vw;
        
     
     }
     /*
     #EDECEC
     */
     .homebody-left>img{
             
         height: 100vh;
         width: 50vw;
         
         object-fit: cover;
     }
 
     .homebody-right{
   
        margin-right: 10%;
         height: 100vh;
         width: 50vw;
     }


}

@media (max-width:450px){
    .homebody-container{
        
        height: 400vh;
        
  
      
        
    }
    .homebody-wrapper{
        flex-direction: column;
    }

    .homebody-left{
       margin-left: 0%;
        height: 50vh;
        width: 100vw;
       
    
    }
    /*
    #EDECEC
    */
    .homebody-left>img{
            
        height: 50vh;
        width: 100vw;
        
        object-fit: cover;
    }

    .homebody-right{
  
      
        height: 100vh;
        width: 50vw;
    }


    
.homebody-display-flex{
    
    
  

    
    border-bottom: 1px solid rgb(212, 138, 68);
    /* background-color: yellow; */
    width: 80vw;
    height: 10vh;
      
    
  
    
    
}

.homebody-display-flex>h3{
width: 100vw;
    
}
.homebody-display-flex>h4{

    width:25vw;
 
    
}
    
}






