.home-container{

   

    
    /* background: url('images/webbg1.jpg') center center/cover no-repeat; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
    position: absolute;
    
    
    
    
    
 
    
}
.display-flex{
    display: flex;
}