.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */

    /* background-color: rgba(196, 139, 64, 0.2); */

    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    bottom: 0;
  }
  .z-logo{
    width: 50px;
    
  }
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100vw;;
    
  }
  
  .navbar-logo {
    /* color: rgb(119, 109, 109); */
    width: 30vw;
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-family: 'Lato','Arial',sans-serif;
 
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    /* color: rgb(119, 109, 109); */
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-weight: bold;
    /* font-family: "Open Sans",sans-serif; */
    font-family: 'Lato','Arial',sans-serif;
    
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
 
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -200vw;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background-color:#dbe3eb;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 2%;
      width: 50vw;
      
      /* transform: translate(0%, 0%); */
     
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
     
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
  
  @media (max-width:1600px){

    .navbar-logo {
      /* color: rgb(119, 109, 109); */
     font-size: 24px;
   
    }
}


  @media (max-width:450px){

    .navbar-logo {
      /* color: rgb(119, 109, 109); */
     font-size: 24px;
   
    }
    .menu-icon{
      
    }
  }