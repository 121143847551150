.navgiftcard-container{
    background-image: url('../images/toptopimg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
         top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      
      height: 60vh;
      width: 100vw;
     z-index: -2;
     background-color:#dbe3eb;
     pointer-events: none;

    
}
.navgiftcard-title{
    height: 30vh;
    width: 50vw;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: -1; */
    position: absolute;
    
}
.navgiftcard-title>h1{
    text-align: center;
    font-size: 54px;
  
      font-weight: 400;
   
   
    font-family: 'Raleway','Arial',sans-serif;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
}

.navgiftcard-wrapper{
    padding-top: 60vh;
    height: 100vh;
    

}




.navgiftcard-top{
    height: 50vh;
    padding-top: 5%;
    
    
 
}

.navgiftcard-top >img{
    height: 40vh;
   width: 100vw;
   object-fit: contain;
    
   
    
}

.navgiftcard-bot>h2{
    color: #1b4852;
   
   
    width: 100vw;
    
    text-align: center;
    font-size: 22px;
    /* font-family: 'Muli',sans-serif; */
    font-family: 'lato',sans-serif;
  


}
.navgiftcard-bot>h3{
    color: #475052;
   
   
    width: 100vw;
    
    text-align: center;
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  


}

.navgiftcard-bot>p{
    color: #475052;
    width: 100vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
    text-align: center;
    align-self: center;
   
  
}