.pam-container{

/* 
    background-image: url('../images/forus.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
         top: 0;
      left: 0;
      right: 0;
      bottom: 0; */
      height: 50vh;
      width: 100vw;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    
      position: relative;
      
 
      background-color:#dbe3eb;
  
      

}

.pam-wrapper{
    display: flex;
}

.pam-title-left{
   height: 50vh;
   width: 50vw;
   margin-left: 10%;
   text-align: center;

   

  }

  .pam-title-right{
    margin-top: 5%;
    margin-right: 5%;
      transform: scale(1);
    
      height: 25vh;
      width: 35vw;
     
     

  }
  .pam-title-left>a {
    text-align: center;
    font-size: 54px;
  
      font-weight: 400;
   
   
    font-family: 'Raleway','Arial',sans-serif;
    color: #1b4852;
    letter-spacing: 5px;
    
  }

  .pam-title-left>h1 {
    text-align: center;
    font-size: 54px;
  
      font-weight: 400;
   
   
    font-family: 'Raleway','Arial',sans-serif;
    color: #1b4852;
    letter-spacing: 5px;
    
  }
  
  .pam-title-left>h2 {
  
    text-align: center;
    font-size: 32px;
    
    margin-top: 5%;
    margin-bottom: 5%;
    color: #1b4852;
    font-family: 'Lato','Arial', sans-serif;
    
    font-weight: 300;
  }
  @media (max-width:1600px){

    .pam-container{
        height: 70vh;
    }
}

  @media (max-width:450px){


    .pam-container{
      height: 150vh;
    }
    .pam-wrapper{
      display: inline;
    }

    .pam-title-left{
      height: 70vh;
      width: 100vw;
      margin-left: 0%;
   
      
   
     }
   
     .pam-title-right{
     
        transform: scale(1);
      
        height: 40vh;
        width: 100vw;
       
       
  
    }

     .pam-title-left>h1 {

      font-size: 32px;
     }

     .pam-title-left>h2{
       font-size: 24px;
     }

     


  }
  


