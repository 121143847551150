


.homefoot-container{
    background-image: url('../images/a5.png') ;
    height: 100vh;
    width: 100vw;
    background-position: bottom;
    background-size: cover;
 background-repeat: no-repeat;
 pointer-events: none;
 
 /* left: 0;
 right: 0;
 top: 0;
 bottom: 0;
     */
    

   
    
   
    overflow: hidden;
    
    
    /* background-color: rgba(228, 203, 94, 0.877); */
    border-bottom: 1px solid rgb(160, 145, 132);
}


.homefoot-wrapper{

    display: flex;
}
/* .foot-describe{
height: 50vh;
width: 80vw;
margin: auto;
} */


.homefoot-display-flex{
    
    
    display:flex;
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 25vw;
        height: 5vh;
      
    
  
    
    
}
.move-to-right{
  margin-right: 10%;
    right: 0;
    position: absolute;
}
.homefoot-right > p {
    text-align: left;
    line-height: 1.7em;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
    margin-bottom: 10%;

}
.homefoot-right{
  
    margin-left: 10%;
    height: 50vh;
    width: 40vw;
}
.homefoot-right>h2{
    text-align: end;
    letter-spacing: 2px;
    font-size: 36px;
    font-family: 'Marcellus',Georgia,"Times New Roman",serif;

}
.homefoot-display-flex>h3{
    color: #475052;
    width: 15vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;

    align-self: center;
    text-align: center;
    
    
}
.homefoot-display-flex>h4{
    color: #1b4852;
    width:10vw;
    margin-left: 5%;
    
    font-size: 22px;
    font-family: 'Muli',sans-serif;
 
    align-self: center;
    text-align: end;
    
}








.homefoot-left{
    margin-left: 10%;
    height: 50vh;
    width: 30vw;
   

}
/*
#EDECEC
*/
.homefoot-left>img{
        
    height: 50vh;
    width: 30vw;
    
    object-fit: contain;
}
@media (max-width:1600px){
    .homefoot-container{
        height: 200vh;
    }

    
    .homefoot-left{
        margin-left: 0%;
         height: 100vh;
         width: 50vw;
        
     
     }
     /*
     #EDECEC
     */
     .homefoot-left>img{
             
         height: 100vh;
         width: 50vw;
         
         object-fit: cover;
     }
 
     .homefoot-right{
   
        margin-right: 10%;
         height: 100vh;
         width: 50vw;
     }


}

@media (max-width:450px){
    .homefoot-container{
        height: 350vh;
        
       
     

    }
    .homefoot-wrapper{
        flex-direction: column;
    }

    .homefoot-left{
       margin-left: 0%;
        height: 50vh;
        width: 100vw;
       
    
    }
    /*
    #EDECEC
    */
    .homefoot-left>img{
            
        height: 50vh;
        width: 100vw;
        
        object-fit: cover;
    }

    .homefoot-right{
  
   
        height: 100vh;
        width: 50vw;
    }


    
.homefoot-display-flex{
    
    
  
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 50vw;
        height: 5vh;
      
    
  
    
    
}

.homefoot-display-flex>h3{
width: 25vw;
    
}
.homefoot-display-flex>h4{

    width:25vw;
 
    
}
    
}





