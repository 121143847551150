.hmt-container{
    /* background-image: url('../images/reachout.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; */
    padding-top: 5%;
  
    height: 30vh;
    width: 100vw;
    background-color:#dbd4d7;
    pointer-events: none;

}



.hmt-container>h1{
    color: #475052;
  
    
    text-align: center;
    font-size: 32px;
    font-family: 'Muli',sans-serif;

}

@media (max-width:450px){

    .hmt-container{
        height: 50vh;
    }
}
