


.homefacial-container{
    background-image: url('../images/a4.png') ;
    height: 50vh;
    width: 100vw;
    background-position:top ;
    background-size: contain;
 background-repeat: no-repeat;
 background-color: #EDECEC;

 pointer-events: none;
    /* overflow: hidden; */
    border-bottom: 1px solid rgb(160, 145, 132);
}

.homefacial-wrapper{

    display: flex;
}

.homefacial-left{
    margin-left: 10%;
    height: 50vh;
    width: 70vw;
  

    
    

}
.homefacial-left-price{
    height: 50vh;
    
   
   
   
}
.homefacial-display-flex{
    
    display:flex;
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 25vw;
        height: 5vh;
      
    
  
    
    
}
.homefacial-left>h2{
    text-align: end;
    letter-spacing: 2px;
    font-size: 36px;
    font-family: 'Marcellus',Georgia,"Times New Roman",serif;

}
.homefacial-display-flex>h3{
    color: #475052;
    width: 15vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
text-align: center;
    align-self: center;
    
}
.homefacial-display-flex>h4{
    color: #1b4852;
    text-align: end;
    margin-left: 5%;
    width: 10vw;
    
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  
    align-self: center;
    
}
.homefacial-left > p {
    text-align: left;
    line-height: 1.7em;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
  

}
.homefacial-right{
    margin-right: 10%;
    height: 50vh;
    width: 30vw;
   

}
/*
#EDECEC
*/
.homefacial-right>img{
        
    height: 50vh;
    width: 30vw;
    
    object-fit: contain;
}




/*home facial */
.homefacial{
    color: #1b4852;
    text-align: start;
    margin-left: 5%;
    width: 13vw;
    
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  
    align-self: center;
}

@media (max-width:1600px){
    .homefacial-container{
        height: 100vh;
    }

    
    .homefacial-right{
        margin-left: 0%;
         height: 100vh;
         width: 50vw;
        
     
     }
     /*
     #EDECEC
     */
     .homefacial-right>img{
             
         height: 100vh;
         width: 50vw;
         
         object-fit: cover;
     }
 
     .homefacial-left{
   
        margin-right: 10%;
         height: 100vh;
         width: 50vw;
     }


}



@media (max-width:450px){
    .homefacial-container{
        padding-bottom: 15%;
        height: 200vh;
      
        border-top: 1px solid rgb(160, 145, 132);
       
      

    }
    .homefacial-wrapper{
        flex-direction: column-reverse;
    }

    .homefacial-right{
       margin-left: 0%;
        height: 50vh;
        width: 100vw;
       
    
    }
    /*
    #EDECEC
    */
    .homefacial-right>img{
            
        height: 50vh;
        width: 100vw;
        
        object-fit: cover;
    }

    .homefacial-left{
  
   
        height: 100vh;
        width: 50vw;
      
    }


    
.homefacial-display-flex{
    
    
    
  
      
        border-bottom: 1px solid rgb(212, 138, 68);
        /* background-color: yellow; */
        width: 80vw;
        height: 10vh;
      
    
  
    
    
}

.homefacial-display-flex>h3{
width: 100vw;
    
}
.homefacial-display-flex>h4{

    width:25vw;
 
    
}
    
}





