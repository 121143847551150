.contact-container{


    background-image: url('../images/toptopimg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
         top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
     z-index: -2;
     overflow: hidden;
     position: absolute;
     pointer-events: none;

      

}


.contact-title{
    height: 50vh;
    width: 50vw;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: -1; */
    position: absolute;
 
   
  /*   
    background-color: red; */
  }
  .p3 {
    text-align: center;
    font-size: 54px;
  
      font-weight: 400;
   
   
    font-family: 'Raleway','Arial',sans-serif;
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    
  }
  
  .p2 {
  
    text-align: center;
    font-size: 32px;
    
    margin-top: 5%;
    margin-bottom: 5%;
    color: #ffffffB3;
    font-family: 'Lato','Arial', sans-serif;
    
    font-weight: 300;
  }
  
@media (max-width:450px){

  .contact-title{
    height: 70vh;
    width: 80vw;
  }
  .p3{
    font-size: 32px;
  }
  .p2{
    font-size: 24px;
    color: #ffffffB3;
  
  }

}

