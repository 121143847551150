


.giftcard-container{
  
    height: 100vh;
    width: 100vw;
   
 background-repeat: no-repeat;
 /* background-color: #EDECEC; */
 background-color:#dbe3eb;
 border-bottom: 1px solid rgb(160, 145, 132);
 
 

  
    overflow: hidden;
   
}


.giftcard-top{
    height: 50vh;
    padding-top: 5%;
    
    
 
}

.giftcard-top >img{
    height: 40vh;
   width: 100vw;
   object-fit: contain;
   pointer-events: none;
   
    
}

.giftcard-bot>h2{
    color: #1b4852;
   
   
    width: 100vw;
    
    text-align: center;
    font-size: 22px;
    /* font-family: 'Muli',sans-serif; */
    font-family: 'lato',sans-serif;
  


}
.giftcard-bot>h3{
    color: #475052;
   
   
    width: 100vw;
    
    text-align: center;
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  


}
.giftcard-bot > a {
        color: #475052;
   
   
    width: 100vw;
    
    text-align: center;
    font-size: 22px;
    font-family: 'Muli',sans-serif;
  
}
.giftcard-bot{
    text-align: center;
}

.giftcard-bot>p{
    color: #475052;
    width: 100vw;
    font-size: 14px;
    font-family: 'Muli',sans-serif;
    text-align: center;
    align-self: center;
    
   
  
}
@media (max-width:1600px){

    .giftcard-container{
        height: 150vh;
    }
}

@media (max-width:450px){

    .giftcard-container{
        height: 150vh;
    }
}