/* .img{
  pointer-events: none;
} */
.hometop-container{
  background-image: url('../images/toptopimg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
       top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
   z-index: -2;
   overflow: hidden;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   object-fit: contain;
   
    /* position: absolute; */
  

      
}

.hometop-title{
  height: 10vh;
  width: 50vw;
  margin: auto;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: -1; */
  position: absolute;

 
/*   
  background-color: red; */
}
/* .p3 {
  text-align: center;
  font-size: 54px;

    font-weight: 400;
 
 
  font-family: 'Raleway','Arial',sans-serif;
  color: rgb(255, 255, 255);
  letter-spacing: 5px;
  
} */

.p22 {

  text-align: center;
  font-size: 32px;
  
  margin-top: 5%;
  margin-bottom: 5%;
  color: #ffffffB3;
  font-family: 'Lato','Arial', sans-serif;
  
  font-weight: 300;
}
.ccus{
  cursor: pointer;
}
.contact-us {

  text-align: center;
  color: #ffffff;
  /* color: rgb(190, 131, 62); */
  font-size: 16px;
  left: 0;
  right: 0;
  margin-left: 30%;
  position:absolute;
  padding: 15px 60px;
  border-radius: 50px; 
  outline: none;
  border: none;
  border: 2px solid rgb(180, 160, 160);
  width: 20%;
  font-family: 'Lato','Arial', sans-serif;
  cursor: pointer;
 
 
}


@media (max-width:450px){


 .hometop-title{
   height: 0vh;
 }
  .p22 {
margin: none;
  margin-top: 0%;
  margin-bottom: 10%;
  font-size: 24px;
  }
  
  .contact-us {
  
    font-size: 14px;
    
    margin-left: -7%;
    width: 25vw;
    
   

   
   
  }
  
  


}